import { FaPhoneAlt } from "react-icons/fa";
import { MdEmail } from "react-icons/md";
import { FaLocationDot } from "react-icons/fa6";
import { ReactComponent as Logo } from "./LogoOKEnterprises.svg";
import { FaWhatsapp } from "react-icons/fa";
import { FaFacebook } from "react-icons/fa";
import { FaInstagram } from "react-icons/fa";

import { ReactComponent as Team } from "./Team.svg";

import { useState } from "react"

function App() {

  const [HamMenuOpen, setHamMenuOpen] = useState(false);

  const sendWhatsapp = (e) => {
    e.preventDefault();

    const emailName = document.getElementById("name").value;
    const emailEmail = document.getElementById("email").value;
    const emailAddress = document.getElementById("address").value;
    const emailNumber = document.getElementById("contactNumber").value;
    const emailMessage = document.getElementById("enquiry").value;

    if (
      emailName === "" ||
      emailEmail === "" ||
      emailAddress === "" ||
      emailNumber === "" ||
      emailMessage === ""
    ) {
      alert("Please Fill All Entries");
      return;
    }
    if (emailNumber.length > 10) {
      alert("Please Enter A Valid Number");
      return;
    }

    const textData = `-----Enquiry Form-----%0AName: ${emailName}%0AEmail: ${emailEmail}%0AAddress: ${emailAddress}%0APhone Number: ${emailNumber}%0AEnquiry: ${emailMessage}`;

    window.location = `https://api.whatsapp.com/send?phone=7900646578&text=${textData}`;
  }

  return (
    <div className="w-full h-fit bg-white snap-y snap-mandatory">
      {/* Home Section */}
      <section className="min-h-screen snap-center text-white bg-[url('bg.jpg')] bg-cover" id="home">
        <nav className="w-full h-full">
          <div className="navbar px-4 lg:12 xl:px-20 items-center flex bg-neutral justify-between">
            <div className="flex items-end">
              <Logo className="w-24 h-fit" />
              <p className="text-lg lg:text-2xl xl:text-3xl font-bold">Enterprises</p>
            </div>

            {/* Hamburger Icon (Mobile View) */}
            <div className="lg:hidden">
              <button className="btn btn-square btn-ghost" onClick={() => { setHamMenuOpen(!HamMenuOpen) }}>
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" className="w-6 h-6">
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M4 6h16M4 12h16m-7 6h7" />
                </svg>
              </button>
            </div>

            {/* Desktop Menu */}
            <div className="hidden lg:flex items-center">
              <ul className="menu menu-horizontal xl:text-lg font-bold">
                <li><a href="#home">Home</a></li>
                <li><a href="#services">Our Services</a></li>
                <li><a href="#about">About Us</a></li>
                <li><a href="#contact">Contact</a></li>
              </ul>
            </div>

            {/* Contact Info (Visible only on desktop) */}
            <div className="hidden lg:flex space-x-2 lg:text-sm xl:text-base">
              <div>
                <div className="flex items-center space-x-2">
                  <FaPhoneAlt />
                  <h6>+91-7900646578</h6>
                </div>
                <div className="flex items-center space-x-2">
                  <MdEmail />
                  <h6>okenterprises0786@gmail.com</h6>
                </div>
              </div>
              <a href="#contact" className="btn btn-outline bg-primary border-primary text-xl md:text-xl w-fit py-2 h-fit border-2 hover:border-primary hover:text-primary">Lets Talk</a>
            </div>
          </div>

          {/* Mobile Menu (Hidden by default) */}
          <div id="mobile-menu" className={`${HamMenuOpen ? "flex" : "hidden"} lg:hidden flex-col items-center bg-neutral/90 text-white p-4 font-bold text-xl space-y-2`}>
            <a href="#home" >Home</a>
            <a href="#services">Our Services</a>
            <a href="#about">About Us</a>
            <a href="#contact" >Contact</a>
          </div>
        </nav>

        <div className="w-full h-full min-h-screen flex flex-col md:flex-row px-4 md:px-8 xl:px-14 pt-4 md:pt-0">
          <div className="flex flex-col space-y-4 xl:space-y-14 w-full text-center justify-center items-center">
            <h1 className="text-4xl md:text-6xl xl:text-7xl font-bold w-full md:w-[60%] bg-neutral/50 backdrop-blur-sm text-sky-300 p-4 rounded-xl">Specialized In Erection & Fabrication of Pre-Engineered Buildings</h1>
            <p className="text-lg md:text-3xl leading-relaxed font-medium text-center w-full md:w-[70%] bg-neutral/50 backdrop-blur-sm p-4 rounded-xl">We specialize in fabrication of premium cold rooms, clean rooms, security cabins, porta cabins, and labour quarters accommodations. Our expertise in installing puff panels and color-coated sheets ensures durable, efficient, and tailored solutions for various industries, meeting your unique project needs with precision and quality.</p>
            <a href="#contact" className="btn btn-outline bg-primary border-primary text-xl md:text-4xl w-fit py-2 h-fit">Lets Talk</a>
          </div>
        </div>
      </section>


      {/* Our Services Section */}
      <section className="min-h-screen snap-center" id="services">
        <div className="w-full flex bg-primary py-4 flex-wrap justify-between items-center space-y-8 md:space-y-0 md:flex-nowrap">
          {/* Project Info */}
          <div className="flex flex-col justify-center items-center w-full md:w-1/3">
            <h2 className="text-5xl font-bold">50+</h2>
            <h2 className="text-2xl md:text-4xl font-medium text-center">Projects Completed</h2>
          </div>
          {/* Satisfaction */}
          <div className="flex flex-col justify-center items-center w-full md:w-1/3">
            <h2 className="text-5xl font-bold">100%</h2>
            <h2 className="text-2xl md:text-4xl font-medium text-center">Customer Satisfaction</h2>
          </div>
          {/* Experts */}
          <div className="flex flex-col justify-center items-center w-full md:w-1/3">
            <h2 className="text-5xl font-bold">Top 1%</h2>
            <h2 className="text-2xl md:text-4xl font-medium text-center">Professional Experts</h2>
          </div>
        </div>

        <h2 className="text-5xl md:text-8xl font-bold text-center py-4 md:py-8">Our Services</h2>

        <div className="grid grid-cols-1 md:grid-cols-4 gap-8 md:px-18 xl:px-32 p-4">
          {/* Service Items */}
          {/* Repeat similar for each service */}
          <div className="w-full h-40 md:h-60 rounded-xl bg-[url('CleanRoom.jpg')] bg-cover">
            <div className="w-full h-full rounded-xl flex justify-center items-center text-center backdrop-brightness-50 text-white font-bold ">
              <h4 className="text-2xl md:text-4xl">Clean Rooms</h4>
            </div>
          </div>
          <div className="w-full h-40 md:h-60 rounded-xl bg-[url('ColdRoom.jpg')] bg-cover">
            <div className="w-full h-full rounded-xl flex justify-center items-center text-center backdrop-brightness-50 text-white font-bold ">
              <h4 className="text-2xl md:text-4xl">Cold Rooms</h4>
            </div>
          </div>
          <div className="w-full h-40 md:h-60 rounded-xl bg-[url('SecurityCabin.jpg')] bg-cover">
            <div className="w-full h-full rounded-xl flex justify-center items-center text-center backdrop-brightness-50 text-white font-bold ">
              <h4 className="text-2xl md:text-4xl">Security Cabins</h4>
            </div>
          </div>
          <div className="w-full h-40 md:h-60 rounded-xl bg-[url('PortaCabin.jpg')] bg-cover">
            <div className="w-full h-full rounded-xl flex justify-center items-center text-center backdrop-brightness-50 text-white font-bold ">
              <h4 className="text-2xl md:text-4xl">Porta Cabins</h4>
            </div>
          </div>
          <div className="w-full h-40 md:h-60 rounded-xl bg-[url('LabourRoom.jpg')] bg-cover">
            <div className="w-full h-full rounded-xl flex justify-center items-center text-center backdrop-brightness-50 text-white font-bold ">
              <h4 className="text-2xl md:text-4xl">Labour Quarters</h4>
            </div>
          </div>
          <div className="w-full h-40 md:h-60 rounded-xl bg-[url('PUFPanels.jpg')] bg-cover">
            <div className="w-full h-full rounded-xl flex justify-center items-center text-center backdrop-brightness-50 text-white font-bold ">
              <h4 className="text-2xl md:text-4xl">PUF Panels Installations</h4>
            </div>
          </div>
          <div className="w-full h-40 md:h-60 rounded-xl bg-[url('ColoredSheets.jpg')] bg-cover">
            <div className="w-full h-full rounded-xl flex justify-center items-center text-center backdrop-brightness-50 text-white font-bold ">
              <h4 className="text-2xl md:text-4xl">Color Coated Sheets Installations</h4>
            </div>
          </div>
          <div className="w-full h-40 md:h-60 rounded-xl bg-[url('More.jpg')] bg-cover">
            <div className="w-full h-full rounded-xl flex justify-center items-center text-center backdrop-brightness-50 text-white font-bold ">
              <h4 className="text-2xl md:text-4xl">And More</h4>
            </div>
          </div>
        </div>
      </section>

      <section className="min-h-fit snap-center px-8 md:px-18 xl:px-32 bg-neutral text-white" id="about">
        <div className="flex flex-col md:flex-row items-center justify-center">
          <div className="h-full w-full md:w-1/2">
            <h2 className="text-4xl md:text-6xl lg:text-8xl font-bold text-primary pt-8">About Us</h2>
            <p className="text-lg md:text-xl lg:text-2xl my-8 text-justify">
              At OK Enterprises, we specialize in fabricating high-quality cold rooms, clean rooms, security cabins, porta cabins, labor quarters, and more.
              With years of experience, we deliver custom-built solutions tailored to your project needs. Our expertise in installing puff panels and
              color-coated sheets ensures durability and precision in every build. Whether it's for industrial, commercial, or specialized applications,
              we are committed to providing reliable and efficient construction services that meet the highest standards.
            </p>
          </div>
          <div className="w-full md:w-1/2 mt-8 md:mt-0">
            <Team className="w-full h-auto" />
          </div>
        </div>
      </section>

      {/* Contact Section */}
      <section className="min-h-screen snap-center flex flex-col justify-between px-4 md:px-18 xl:px-32 bg-neutral" id="contact">
        <div className="flex flex-col md:flex-row h-full">
          <div className="w-full md:w-1/2 h-full px-8 xl:px-24">
            <h2 className="text-5xl md:text-8xl font-bold py-8 text-center">Lets Talk</h2>
            {/* Form */}
            <form className="flex flex-col space-y-4 md:space-y-6 w-full font-medium">
              <input id="name" type="text" placeholder="Name" className="placeholder-black/60 input input-bordered rounded-xl w-full" />
              <input id="email" type="email" placeholder="Email" className="placeholder-black/60 input input-bordered rounded-xl w-full" />
              <input id="address" type="text" placeholder="Address" className="placeholder-black/60 input input-bordered rounded-xl w-full" />
              <input id="contactNumber" type="number" placeholder="Contact Number" className="placeholder-black/60 input input-bordered rounded-xl w-full" />
              <textarea id="enquiry" className="textarea textarea-bordered rounded-xl placeholder-black/60" placeholder="Enquiry"></textarea>

              <button onClick={e => sendWhatsapp(e)} type="submit" className="btn btn-success w-full rounded text-white text-lg">Send Through <FaWhatsapp></FaWhatsapp></button>
            </form>
          </div>
          <div className="w-full md:w-1/2 bg-neutral h-full">
            <h2 className="text-5xl md:text-8xl font-bold py-8 text-center text-white">Contact Us</h2>
            {/* Contact Info */}
            <div className="w-full text-lg lg:text-xl xl:text-3xl font-bold text-primary px-4 md:px-24 flex flex-col justify-center space-y-10">
              {/* Email, Address, Call */}
              <div><div className="flex space-x-2 items-center"><MdEmail /> <h4>Email Us</h4></div> <p className="font-normal text-white">okenterprises0786@gmail.com</p></div>
              <div><div className="flex space-x-2 items-center"><FaLocationDot /> <h4>Our Address</h4></div> <p className="font-normal text-white">Plot No. 48, Near Bunty Filling Station, Aslampur, Muzaffarpur, Sambhal, Uttar Pradesh 244303</p></div>
              <div><div className="flex space-x-2 items-center"><FaPhoneAlt /> <h4>Call Us</h4></div> <p className="font-normal text-white">+91-7900646578</p> <p className="font-medium text-primary">Mr. Owais Khan</p></div>
            </div>
          </div>
        </div>
        <footer className="footer bg-neutral text-neutral-content p-10">
          <div className="flex flex-col md:flex-row justify-between items-center w-full space-y-8 md:space-y-0">
            <aside className="text-center md:text-left">
              <Logo className="w-32 h-auto mx-auto md:mx-0" />
              <p>
                <span className="text-lg font-bold">OK Enterprises</span><br />
                All Rights Reserved.
              </p>
              <p>Created By <a href="www.yoursoftware.engineer">Rajvir Singh</a>.</p>
            </aside>

            <nav className="text-center md:text-left">
              <h6 className="footer-title text-white text-xl">Social</h6>
              <div className="grid grid-flow-col gap-4 justify-center md:justify-start text-primary">
                <FaWhatsapp className="size-10 cursor-pointer" />
                <FaInstagram className="size-10 cursor-pointer" />
                <FaFacebook className="size-10 cursor-pointer" />
              </div>
            </nav>
          </div>
        </footer>
      </section>
    </div>
  );
}


export default App;
